// Return a base URL for accessing the server endpoints
export const renderServerBase = () => {
	// Get the hostname of the current request
	const currentHost = window.location.hostname

	// Production domains for both apps, used to set the scope
	const ORBR_Domains = ["trackside-hospitality.redbull.racing", "www.trackside-hospitality.redbull.racing"]
	const VCARB_Domains = ["trackside-hospitality-vcarb.herokuapp.com", "www.trackside-hospitality-vcarb.herokuapp.com"]

	// Check the environment we're currently running in
	if (ORBR_Domains.includes(currentHost)) {
		// Production host
		return "https://trackside-hospitality.redbull.racing/api"
	} else if (VCARB_Domains.includes(currentHost)) {
		// Production host
		return "https://trackside-hospitality-vcarb.herokuapp.com/api"
	} else if (currentHost === "localhost" || currentHost === "") {
		// Local host server
		return "http://localhost:8080/api"
	} else {
		// Anything else, process on the staging host
		return "https://trackside-hospitality-stage.herokuapp.com/api"
	}
}

// Returns the relevant API key for the environment
export const renderServerKey = () => {
	// Get the hostname of the current request
	const currentHost = window.location.hostname

	// Production domains for both apps, used to set the scope
	const ORBR_Domains = ["trackside-hospitality.redbull.racing", "www.trackside-hospitality.redbull.racing"]
	const VCARB_Domains = ["trackside-hospitality-vcarb.herokuapp.com", "www.trackside-hospitality-vcarb.herokuapp.com"]

	// Check the environment we're currently running in
	if (ORBR_Domains.includes(currentHost)) {
		// Production key
		return "2eb35cb26b5b4a40b851e4e47b71fd91"
	} else if (VCARB_Domains.includes(currentHost)) {
		// Production key
		return "8d3d12947c6d47369a9c775af9f36adc"
	} else {
		// Stating key
		return "f24e14e351f3413eafa4ba194c4d845d"
	}
}

// Returns the relevant scope key for local storage elements
export const renderStorageKey = () => {
	// Get the hostname of the current request
	const currentHost = window.location.hostname

	// Production domains for both apps, used to set the scope
	const ORBR_Domains = ["trackside-hospitality.redbull.racing", "www.trackside-hospitality.redbull.racing"]
	const VCARB_Domains = ["trackside-hospitality-vcarb.herokuapp.com", "www.trackside-hospitality-vcarb.herokuapp.com"]

	// Check the environment we're currently running in
	if (ORBR_Domains.includes(currentHost)) {
		// Production key
		return "TH_ORBR_"
	} else if (VCARB_Domains.includes(currentHost)) {
		// Production key
		return "TH_VCARB_"
	} else {
		// Stating key
		return "TH_ORBR_"
	}
}
