import { Navigate, Routes, Route, useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useResolvedPath } from "react-router-dom"
import moment from "moment"
import "./event.scss"

// Utility functions
import { safeDataFetch } from "../../utils/database"

// UI components
import { CosmosButton, CosmosIconArrowLeft, CosmosIconSettings, CosmosText, CosmosTitle } from "@cosmos/web/react"
import Loading from "../../components/loading/loading"
import CountryFlag from "../../components/flag/flag"
import Status from "../../components/status/status"

// Events routes
import Guests from "./tabs/guests"
import Groups from "./tabs/groups"
import Redemption from "./tabs/redemption"
import Settings from "./tabs/settings"
import EditGroup from "./tabs/group/edit"
import DeleteGroup from "./tabs/group/delete"
import Tours from "./tabs/tours"
import Tour from "../tour/tour"
import Predictor from "./tabs/predictor"
import Passes from "./tabs/passes"
import Radios from "./tabs/radios"

// Returns the markup for viewing an event
export default function Event({}) {
	// Make an instance of the useNavigate function from the router
	const navigate = useNavigate()

	// Get the event ID
	const { eventID } = useParams()

	// If no event ID is preset, redirect back to /events
	if (!eventID) {
		return <Navigate to="/events" />
	}

	// Container states
	const [loading, setLoading] = useState(true)
	const [pitwallID, setPitwallID] = useState("")
	const [name, setName] = useState("")
	const [dates, setDates] = useState("")
	const [type, setType] = useState("")
	const [location, setLocation] = useState("")
	const [country, setCountry] = useState("")
	const [countryCode, setCountryCode] = useState("")
	const [entryOpen, setEntryOpen] = useState("")
	const [entryClose, setEntryClose] = useState("")
	const [eventDates, setEventDates] = useState({})
	const [tourGuides, setTourGuides] = useState([])

	// Store a reference to the current route location
	let routeLocation = useResolvedPath()

	// When the eventID is updated
	useEffect(() => {
		// Request the event details from the database
		const fetchEventDetails = async () => {
			const eventData = await safeDataFetch("GET", `/events/${eventID}`, null, `EVENT_${eventID}`)

			// Set them into the state
			setPitwallID(eventData.pitwall_uuid)
			setName(eventData.name)
			setDates(eventData.date_string)
			setType(eventData.time_period)
			setLocation(eventData.location)
			setCountry(eventData.country)
			setCountryCode(eventData.country_code)
			setEntryOpen(eventData.entry_open)
			setEntryClose(eventData.entry_close)

			// Get the start and end date of the event
			let dateCursor = moment(eventData.event_start)
			const dateTo = moment(eventData.event_end)

			// Setup a new object for storing the available event dates
			let dates = {}

			// Loop from the start date (referenced as dateCursor) through until the end date
			while (dateCursor.isSameOrBefore(dateTo)) {
				// Write the current date into the
				dates[`${dateCursor.format("DD/MM/YYYY")}`] = dateCursor.format("dddd, DD/MM/YYYY")

				// Add a day onto the cursor
				dateCursor.add(1, "days")
			}

			// Then set these dates into the state
			setEventDates(dates)

			// Toggle the loading state
			setLoading(false)
		}
		fetchEventDetails()

		// At an event level, also attempt to fetch the list of available tour guides
		const fetchTourGuides = async () => {
			const guides = await safeDataFetch("GET", "/users/guides", null, "TOUR_GUIDES")

			// If the request was successful, set the tour guides into the state
			if (guides) {
				setTourGuides(guides)
			}
		}
		fetchTourGuides()
	}, [eventID])

	return (
		<section className="thosp-section">
			<div className="thosp-header">
				<CosmosButton
					size="small"
					kind="tertiary"
					appearance="light"
					onClick={() => navigate("/events")}>
					<CosmosIconArrowLeft slot="icon" />
					Events
				</CosmosButton>

				<Loading
					active={loading}
					label="Fetching event..."
					style={{ marginTop: "22px" }}
				/>

				{!loading && (
					<>
						<div className="thosp-event-name">
							<CosmosTitle
								appearance="light"
								spacing="long-form-top">
								{name}
							</CosmosTitle>

							<CosmosButton
								className="thosp-event-settings"
								appearance="light"
								shape="square"
								size="small"
								kind="secondary"
								onClick={() => navigate(`/event/${eventID}/settings`)}>
								<CosmosIconSettings />
							</CosmosButton>
						</div>

						<div className="thosp-event-date no-wrap">
							<CosmosText
								appearance="light"
								size="small"
								spacing="none">
								{dates}
							</CosmosText>

							<Status type={type} />
						</div>

						<div className="thosp-event-location">
							<CountryFlag countryCode={countryCode} />

							<CosmosText
								appearance="light"
								size="x-small"
								spacing="none">
								{location}, {country}
							</CosmosText>
						</div>

						<div className="thosp-tab-navigation slimmed">
							<CosmosButton
								appearance="light"
								size="medium"
								kind="link"
								hovered={routeLocation.pathname.endsWith("/guests")}
								className="navigation-button"
								onClick={() => navigate(`/event/${eventID}/guests`)}>
								Guests
							</CosmosButton>
							<CosmosButton
								appearance="light"
								size="medium"
								kind="link"
								hovered={routeLocation.pathname.endsWith("/groups")}
								className="navigation-button"
								onClick={() => navigate(`/event/${eventID}/groups`)}>
								Groups
							</CosmosButton>
							<CosmosButton
								appearance="light"
								size="medium"
								kind="link"
								hovered={routeLocation.pathname.endsWith("/tours")}
								className="navigation-button"
								onClick={() => navigate(`/event/${eventID}/tours`)}>
								Tours
							</CosmosButton>
							<CosmosButton
								appearance="light"
								size="medium"
								kind="link"
								hovered={routeLocation.pathname.endsWith("/passes")}
								className="navigation-button"
								onClick={() => navigate(`/event/${eventID}/passes`)}>
								Passes
							</CosmosButton>
							<CosmosButton
								appearance="light"
								size="medium"
								kind="link"
								hovered={routeLocation.pathname.endsWith("/radios")}
								className="navigation-button"
								onClick={() => navigate(`/event/${eventID}/radios`)}>
								Radios
							</CosmosButton>
							<CosmosButton
								appearance="light"
								size="medium"
								kind="link"
								hovered={routeLocation.pathname.endsWith("/redemption")}
								className="navigation-button"
								onClick={() => navigate(`/event/${eventID}/redemption`)}>
								Redemption
							</CosmosButton>
							<CosmosButton
								appearance="light"
								size="medium"
								kind="link"
								hovered={routeLocation.pathname.endsWith("/predictor")}
								className="navigation-button"
								onClick={() => navigate(`/event/${eventID}/predictor`)}>
								Predictor
							</CosmosButton>
						</div>
					</>
				)}
			</div>

			{!loading && (
				<Routes>
					<Route
						path="/guests"
						element={
							<Guests
								eventName={name}
								eventID={eventID}
								location={location}
								country={country}
								countryCode={countryCode}
								eventDates={eventDates}
							/>
						}
					/>
					<Route
						path="/groups"
						element={
							<Groups
								eventID={eventID}
								eventDates={eventDates}
							/>
						}
					/>
					<Route
						path="/group/:groupID/edit"
						element={<EditGroup eventID={eventID} />}
					/>
					<Route
						path="/group/:groupID/delete"
						element={<DeleteGroup eventID={eventID} />}
					/>
					<Route
						path="/tours"
						element={
							<Tours
								eventID={eventID}
								eventDates={eventDates}
								tourGuides={tourGuides}
							/>
						}
					/>
					<Route
						path="/passes"
						element={<Passes eventID={eventID} />}
					/>
					<Route
						path="/radios"
						element={<Radios eventID={eventID} />}
					/>
					<Route
						path="/tour/:tourID"
						element={<Tour />}
					/>
					<Route
						path="/redemption"
						element={<Redemption eventID={eventID} />}
					/>
					<Route
						path="/predictor"
						element={<Predictor eventID={pitwallID} />}
					/>
					<Route
						path="/settings"
						element={
							<Settings
								eventID={eventID}
								open={entryOpen}
								close={entryClose}
							/>
						}
					/>

					<Route
						path="*"
						element={<Navigate to={`/event/${eventID}/guests`} />}
					/>
				</Routes>
			)}
		</section>
	)
}
