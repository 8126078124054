export const eventRedemptions = {
	46: [
		// Dutch Grand Prix 2024
		{
			code: "PEPE_15Nether24",
			description: "Pepe gift for those attending Zanvoort 2024",
		},
	],
	50: [
		// United States Grand Prix 2024
		{
			code: "YETI_19USA24",
			description: "Custom Yeti gift for those attending Austin 2024",
		}
	]
}
