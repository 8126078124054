import { useState, useEffect } from "react"
import moment from "moment"

// Utility functions
import { safeDataFetch, safeRequest } from "../../../utils/database"

// UI components
import { CosmosInput, CosmosTitle, CosmosText, CosmosButton, CosmosIconPlus, CosmosSelect, CosmosOption, CosmosCheckbox } from "@cosmos/web/react"
import Loading from "../../../components/loading/loading"
import TourRow from "./tour/row"


// Returns the markup and functionality for event tours
export default function Tours({ eventID, eventDates, tourGuides }) {
	const [loading, setLoading] = useState(true)
	const [tours, setTours] = useState([])

	// New tour details
	const [errors, setErrors] = useState({})
	const [type, setType] = useState("Garage")
	const [guide, setGuide] = useState("")
	const [startDate, setStartDate] = useState(null)
	const [startTime, setStartTime] = useState(null)
	const [startDateTime, setStartDateTime] = useState(null)
	const [maxGuests, setMaxGuests] = useState(7)
	const [isVcarb, setIsVcarb] = useState(false)

	// Tours management states
	const [adding, setAdding] = useState(false)
	const [addingTour, setAddingTour] = useState(false)
	const [deletingTour, setDeletingTour] = useState(false)
	console.log(tourGuides)

	// On component mount
	useEffect(() => {
		fetchEventTours()
	}, [])

	// When either the start day or time are updates
	useEffect(() => {
		// Do we have values for both available yet?
		if (!startDate || !startTime) return

		// If we do, use them both to parse a full datetime for this tour
		const fullTime = moment(`${startDate} ${startTime}`, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ssZZ")

		// Set the full datetime into the state
		setStartDateTime(fullTime)
	}, [startDate, startTime])

	// Fetch the guest list for a given event ID
	const fetchEventTours = async () => {
		// Pull a list of the tours
		const eventTours = await safeDataFetch("GET", `/event/${eventID}/tours`, {}, `EVENT_TOURS_${eventID}`)

		// Set them into the state
		if (eventTours.length > 0) {
			setTours(eventTours)
		} else {
			setTours([])
		}

		// Reset the loading state
		setLoading(false)
		setDeletingTour(null)
	}

	// Add the new your into the database
	const addNewTour = async () => {
		// Set the loading state
		setAdding(true)

		// Setup an object to hold the errors
		let tourErrors = {}
		if (!type) {
			tourErrors["type"] = "Type cannot be empty"
		}
		if (!guide) {
			tourErrors["guide"] = "Guide cannot be empty"
		}
		if (!startTime || startTime === "Invalid date") {
			tourErrors["startTime"] = "Start time must be set"
		}
		if (!startDate || startDate === "Invalid date") {
			tourErrors["startDate"] = "Day must be set"
		}

		// If there are errors, set them into the state
		if (Object.keys(tourErrors).length > 0) {
			setErrors(tourErrors)
			setAdding(false)
			return
		}

		// Make the request into the database
		await safeRequest("POST", `/event/${eventID}/tour`, { title: type, userID: guide, startTime: startDateTime, maxGuests, is_vcarb: isVcarb })

		// Then re-fetch the event tours
		fetchEventTours()

		// Reset the state
		setAdding(false)
		resetAddingTourState()
	}

	// Reset the state when adding a new tour is cancelled
	const resetAddingTourState = () => {
		// Reset the inputs
		setErrors({})
		setType("Garage")
		setGuide("")
		setStartTime(null)
		setMaxGuests(7)

		// Then toggle the form
		setAddingTour(false)
	}

	// Delete a tour from the event
	const deleteTour = async (tourID) => {
		// Set the tour ID into the state as being deleted
		setDeletingTour(tourID)

		// Delete the tour from the event
		await safeRequest("DELETE", `/tour/${tourID}`)

		// Refetch the tours list
		fetchEventTours()

		// Reset the state
		setDeletingTour(false)
	}

	return (
		<>
			<div className="thosp-header-button">
				<CosmosTitle
					appearance="light"
					size="x-small"
					spacing="none">
					{addingTour ? "Adding Tour" : "Tours"}
				</CosmosTitle>

				{!addingTour && (
					<CosmosButton
						shape="square"
						size="small"
						onClick={() => setAddingTour(!addingTour)}>
						<CosmosIconPlus />
					</CosmosButton>
				)}
			</div>

			<Loading
				active={loading}
				label="Fetching tours..."
			/>

			{tours.length === 0 && !loading && (
				<CosmosText
					appearance="light"
					size="small"
					kind="subtle">
					There are no event tours setup
				</CosmosText>
			)}

			{addingTour && (
				<div className="thosp-add-tour-form">
					<CosmosSelect
						appearance="light"
						label="Type:"
						invalid={errors.type ? true : null}
						validationMessage={errors.type}
						onInputchange={({ detail }) => setType(detail.value)}>
						<CosmosOption
							selected="true"
							value="Garage">
							Garage
						</CosmosOption>
						<CosmosOption
							selected="false"
							value="Viewing Gallery">
							Viewing Gallery
						</CosmosOption>
						<CosmosOption
							selected="false"
							value="Partner">
							Partner
						</CosmosOption>
					</CosmosSelect>

					<CosmosSelect
						appearance="light"
						label="Guide:"
						validationMessage={errors.guide}
						invalid={errors.guide ? true : null}
						onInputchange={({ detail }) => setGuide(detail.value)}>
						{tourGuides?.map((guide) => (
							<CosmosOption value={guide.user_uuid}>{guide.name}</CosmosOption>
						))}
					</CosmosSelect>

					<CosmosSelect
						appearance="light"
						label="Day:"
						validationMessage={errors.startDate}
						invalid={errors.startDate ? true : null}
						onInputchange={({ detail }) => setStartDate(detail.value)}>
						{Object.entries(eventDates).map(([key, date]) => (
							<CosmosOption
								appearance="light"
								value={moment(date, "dddd DD/MM/YYYY").format("DD-MM-YYYY")}>
								{date}
							</CosmosOption>
						))}
					</CosmosSelect>

					<CosmosInput
						type="time"
						appearance="light"
						label="Start time:"
						validationMessage={errors.startTime}
						invalid={errors.startTime ? true : null}
						onInputinput={({ detail }) => setStartTime(detail.value)}
					/>

					<div className="span-2 check-guest-in">
						<CosmosCheckbox
							appearance="light"
							onInputchange={({ detail }) => setIsVcarb(detail.checked)}>
							Is VCARB?
						</CosmosCheckbox>
					</div>

					<div className="span-2 add-tour-buttons">
						<CosmosButton
							appearance="light"
							shape="rectangle"
							size="small"
							kind="secondary"
							onClick={() => resetAddingTourState()}>
							Cancel
						</CosmosButton>

						<CosmosButton
							appearance="light"
							shape="rectangle"
							size="small"
							busy={adding}
							onClick={() => addNewTour()}>
							Add Tour
						</CosmosButton>
					</div>
				</div>
			)}

			<br />

			{!addingTour &&
				tours.map((tour) => (
					<TourRow
						key={tour.id}
						eventID={eventID}
						details={tour}
						deleting={deletingTour}
						is_vcarb={tour.is_vcarb}
						refetchTours={fetchEventTours}
						onDelete={(tourID) => deleteTour(tourID)}
					/>
				))}
		</>
	)
}
